<template lang="pug">
  v-card.presentation__info(flat)
    v-card-text.pt-0
      h1.mb-4 РЕБЕНКУ / РОДИТЕЛЮ базовая версия АИС «ПФДО» в режиме автоматизации позволяет:

      ul
        li Пользоваться доступом к просмотру актуальных возможностей сертификатов детей (количество
          |  доступных муниципальных услуг, информация о номинале сертификата, резерв;
        li Пользоваться доступом к просмотру информации об особенностях реализации ПДО в муниципалитете на
          |  основании программы ПФ и положения о ПДО;
        li Пользоваться доступом к просмотру всех образовательных программ регионального навигатора с учётом
          |  текущих возможностей сертификата;
        li Подавать и отменять заявки на обучение по образовательным программам: сертифицированным, бюджетным и платным;
        li Заполнять и распечатывать заявки на обучение из АИС;
        li Пользоваться доступом к информации по сформированному договору -оферте на обучение по сертифицированной программе;
        li Участвовать в оценке образовательных программ (общая удовлетворённость программой, соответствие содержания программы
          |  заявленным целям и задачам, удовлетворённость педагогом и материально - техническим оснащением);
        li Пользоваться доступом к информации о всех зачислениях и договорах на обучение по образовательным программам
          |  организации;
        li Пользоваться доступом к информации о расписании занятий во всех группах, где обучается ребёнок;
        li Расторгать договоры по сертифицированным и платным программам с направлением уведомления об этом образовательной
          |  организации;
        li Оценивать выраженность интересов ребенка при помощи увлекательной игры;
        li Пользоваться доступом к информации о своих достижениях по результатам участия в конкурсах (портфолио);
        li Формировать справку о посещении кружков (для предоставления по месту требования);
        li Пользоваться доступом к документам и важной контактной информацией ответственных лиц, связанных с внедрением ПФДО в
          |  регионе;
        li Пользоваться доступом к просмотру руководств по работе в АИС «ПФДО» (инструкции, видеоуроки,
          |  часто задаваемые вопросы);
        li Пользоваться рассылку уведомлений о важных событиях, связанных с реализацией ПДО посредством использования АИС
          |  (уведомление о формировании заявок на обучение (защита от несанкционированных зачислений детей образовательными
          |  организациями), подтверждении заявок на обучение, зачислении на образовательную программы, изменении параметров
          |  финансирования сертификатов в муниципалитете);
        li Пользоваться доступом к просмотру новостей в сфере дополнительного образования, размещаемым в
          |  региональном навигаторе дополнительного образования детей;
        li Пользоваться доступом к информации об образовательных организациях включённых в реестр поставщиков образовательных
          |  услуг региона;
        li Указывать удобный адрес для посещения кружков для удобства поиска образовательных программ в навигаторе;
        li Защищать сертификат от несанкционированного зачисления ребёнка со стороны образовательных организаций;
        li Пользоваться доступом к информации о порядке зачисления в образовательную организацию
          |  (организация вносит информацию через свой кабинет);
        li Пользоваться мобильным приложением «ОНФ.Сертификат»:

      image-dialog
        v-img.mt-3(:src="require('@/assets/images/pfdo_app.jpg')")

</template>

<script>
export default {
  name: 'AboutCertificate',
};
</script>

<style scoped>

</style>
