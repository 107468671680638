<template lang="pug">
  v-card.presentation__info(flat)
    v-card-text.pt-0
      h1.mb-4 НЕАВТОРИЗОВАННОМУ ПОЛЬЗОВАТЕЛЮ базовая версия АИС «ПФДО» в режиме автоматизации позволяет:

      ul
        li Направление заявок на включение в реестр поставщиков образовательных услуг;
        li Направление заявки на получение сертификата;
        li Удаление заявки на получение своей заявки на получение сертификата;
        li Пользоваться доступом к просмотру новостей в сфере дополнительного образования, размещаемым в региональном навигаторе
          |  дополнительного образования детей;
        li Пользоваться доступом к просмотру всех образовательных программ регионального навигатора;
        li Пользоваться доступом к информации об образовательных организациях, включённых в реестр поставщиков образовательных
          |  услуг региона;
        li Пользоваться доступом к информации о мероприятиях, реализуемых на территории региона;
        li Пользоваться доступом к документам и важной контактной информацией ответственных лиц, связанных с внедрением ПФДО в
          |  регионе;
        li Пользоваться доступом к просмотру руководств по работе в АИС «ПФДО» (инструкции,
          |  видеоуроки, часто задаваемые вопросы);

</template>

<script>
export default {
  name: 'AboutUser',
};
</script>
