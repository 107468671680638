<template lang="pug">
  v-card.presentation__info(flat)
    v-card-text.pt-0
      p
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            b.primary--text(v-on="on") АИС «ПФДО»
          span Автоматизированная информационная система персонифицированного дополнительного образования детей
        |  была создана командой ИРПСУ, которая принимала участие в написании и разработке правил Целевой модели развития
        |  региональных систем дополнительного образования детей в России, основных идей, правил и принципов персонифицированного
        |  учета и персонифицированного финансирования, концептуальные подходы к развитию дополнительного образования в условиях
        |  введения  ПФДО, поэтому АИС «ПФДО» - это:

      ul
        li не просто система учёта детей с расчётным модулем
        li не просто портал записи в кружки
        li не просто навигатор дополнительного образования
        li АИС «ПФДО» - это полноценная система управления дополнительным образованием детей, автоматизирующая все требуемые
          |  процессы при внедрении персонифицированного дополнительного образования детей, стремящаяся свести «ручной труд» всех
          |  пользователей системы к минимуму.

        li АИС «ПФДО» подразумевает взаимодействие ролей пользователей, каждый из которых получает личные кабинеты в АИС:
        li
          b ВАЖНО!
          |  Система АИС «ПФДО» состоит из функционала базовой версии и модулей, подключаемых дополнительно в зависимости от
          |  потребностей региона в автоматизации определённого функционала сверх того, что включено в базовую версию АИС «ПФДО»
          |  (по запросу готовы предоставить информацию о дополнительных модулях).
        li В чём принципиальное преимущество системы АИС «ПФДО» по сравнению с похожими предложениями?
        li
          b В базовой версии АИС «ПФДО» УЖЕ ВКЛЮЧЕНЫ ВСЕ
          |  функции и модули, необходимые для реализации задачи внедрения Целевой модели, механизмов персонифицированного
          |  дополнительного образования, функционал регионального навигатора дополнительного образования и передачи всех
          |  требуемых данных в ЕАИС ДО.

</template>

<script>
export default {
  name: 'AboutAisPfdo2',
};
</script>
