<template lang="pug">
  v-card.presentation__info(flat)
    v-card-text.pt-0
      p
        b.primary--text АИС «ПФДО» представляет из себя эко-систему,
        span  в которой все участники процесса реализации персонифицированного дополнительного образования детей в нужной
          |  степени влияют на реализацию проекта
      p В АИС «ПФДО» есть все необходимые функциональные блоки управления и автоматизации для следующих задач:

      ul.mb-2
        li УПРАВЛЕНИЕ СИСТЕМОЙ ПЕРСОНИФИЦИРОВАННОГО ФИНАНСИРОВАНИЯ;
        li УПРАВЛЕНИЕ РЕГИОНАЛЬНЫМ НАВИГАТОРОМ ДОПОЛНИТЕЛЬНОГО ОБРАЗОВАНИЯ ДЕТЕЙ;
        li УПРАВЛЕНИЕ ПОДСИСТЕМОЙ РАБОТЫ С ПЕРСОНАЛЬНЫМИ ДАННЫМИ;
        li УПРАВЛЕНИЕ ИНТЕГРАЦИЕЙ СО ВСЕМИ ГОСУДАРСТВЕННЫМИ СИСТЕМАМИ, УЧАСТВУЮЩИМИ В ПРОЕКТЕ;
        li УПРАВЛЕНИЕ ОБРАЗОВАТЕЛЬНЫМИ ПРОГРАММАМИ ДОПОЛНИТЕЛЬНОГО ОБРАЗОВАНИЯ;
        li УПРАВЛЕНИЕ ДЕЯТЕЛЬНОСТЬЮ ОБРАЗОВАТЕЛЬНЫХ ОРГАНИЗАЦИЙ;
        li УПРАВЛЕНИЕ ДОКУМЕНТООБОРОТОМ И ОТЧЁТАМИ СИСТЕМЫ ДОПОЛНИТЕЛЬНОГО ОБРАЗОВАНИЯ;

      p Богатый внутренний мир АИС «ПФДО» самодостаточен в полной мере для реализации персонифицированного дополнительного
        |  образования детей . Обратите внимания, что в базовую версию АИС «ПФДО» входит многое из того, что предлагается
        |  конкурентами за дополнительные средства. (схема направляется отдельно по запросу)

      image-dialog
        v-img.my-3(:src="require('@/assets/images/pfdo_schema.jpg')")

      p: b 30 регионов уже опробовали АИС «ПФДО» и убедились в её эффективности
      svg-map
      p Вологодская область, Тюменская область, Ханты-Мансийский Автономный Округ, Калининградская область, Республика Коми,
        |  Липецкая область, Новгородская область, Тамбовская область, Томская область, Республика Удмуртия, ЯмалоНенецкий
        |  Автономный Округ, Ярославская область, Алтайский край, Волгоградская область, Кемеровская область, Республика
        |  Мордовия, Северная Осетия, Саратовская область, Свердловская область, Хабаровский край, Владимирская область,
        |  Воронежская область, Кабардино-Балкарская Республика, Кировская область, Мурманская область, Сахалинская область,
        |  Пензенская область, Приморский край, Магаданская область, Севастополь.

      p: b Какая же цена вопроса по сопровождению данной системы для региона без ограничения по количеству пользователей?
      p Стоимость технического сопровождения этой огромной эко-системы управления персонифицированным дополнительным
        |  образованием детей АИС «ПФДО» со второго года использования и далее (в ценах 2021 года) составляет всего
        b.secondary--text.ml-1 275 000 рублей в год

</template>

<script>
import SvgMap from '@/components/SvgMap';

export default {
  name: 'AboutAisPfdo2',
  components: { SvgMap },
};
</script>
