<template lang="pug">
  v-card.presentation__info(flat)
    v-card-text.pt-0
      h1.mb-4 ПЕДАГОГАМ ОБРАЗОВАТЕЛЬНЫХ ОРГАНИЗАЦИЙ базовая версия АИС «ПФДО» в режиме автоматизации позволяет:

      ul
        li Добавлять и дорабатывать образовательные программы для навигатора;
        li Пользоваться доступом к просмотру результатов экспертиз образовательных программ;
        li Создавать и редактировать группы обучающихся и расписание занятий для них;
        li Открывать, закрывать возможность зачисления детей на обучение по образовательным программам;
        li Пользоваться доступом к информации о расписании групповых и индивидуальных занятий, в которых преподаёт;
        li Пользоваться доступом к персональным данным детей/ родителей, обучающихся в группах педагога (при согласии родителей);
        li Пользоваться доступом к списку обучающихся детей в группах, в которых педагог преподаёт;
        li Пользоваться доступом к календарному плану всех занятий, которые педагог проводит;
        li Пользоваться доступом к документам и важной контактной информацией ответственных лиц, связанных с внедрением ПФДО в
          |  регионе;
        li Пользоваться доступом к просмотру руководств по работе в АИС «ПФДО» (инструкции, видеоуроки, часто
          |  задаваемые вопросы);
        li Получать рассылку уведомлений о важных событиях, связанных с реализацией ПДО посредством
          |  использования АИС;
        li Пользоваться доступом к просмотру новостей в сфере дополнительного образования, размещаемым в
          |  региональном навигаторе дополнительного образования детей;

</template>

<script>
export default {
  name: 'AboutPedagogue',
};
</script>
