<template lang="pug">
  v-card.presentation__info(flat)
    v-card-text.pt-0
      h1.mb-4 РЕГИОНАЛЬНОМУ ОПЕРАТОРУ / РЕГИОНАЛЬНОМУ МОДЕЛЬНОМУ ЦЕНТРУ базовая версия АИС «ПФДО» в режиме автоматизации
        |  позволяет:

      ul
        li Полностью автоматизировать персонифицированное дополнительное образование являющееся составной частью федерального
          |  проекта «Успех каждого ребенка» и отчитываться по нему Федерации в полном соответствии требованиям Целевой модели;
        li Формировать реестр поставщиков образовательных услуг в полном соответствии с правилами ПФДО;
        li Сопровождать наполнение навигатора программами дополнительного образования;
        li Проводить процедуру добровольной сертификации образовательных программ, направляемых поставщиками (обучение детей по
          |  ним оплачивается за счёт денежных средств сертификата);
        li Вести реестр сертификатов дополнительного образования в регионе;
        li Настраивать параметры АИС в полном соответствии с правилами ПФДО и следить за реализацией системы персонифицированного
          |  дополнительного образования в регионе;
        li Формировать статистическую информацию по использованию сертификатов дополнительного образования и оценивать охват
          |  детей дополнительным образованием в регионе;
        li Формировать простые и аналитические отчёты. (Например: Количество зачислений за указанный период по направленностям
          |  по конкретным организациям; Количество обучающихся за указанный период по направленностям по конкретным
          |  организациям; Сводная информация по стоимости программ; Данные о внедрении системы персонифицированного
          |  финансирования; Сводная информация по количеству программ; Реестры организаций, осуществляющих деятельность по
          |  реализации дополнительны; Импорт и экспорт образовательных услуг);
        li Разрешать конфликтные ситуации, связанные с нарушением процедуры заключения договоров образовательными организациями;
        li  Помогать образовательным организациям сохранять важную информацию (о заключенных договорах на обучение,
          |  образовательных программах и сотрудниках) в ситуации их ликвидации путём их присоединения к другой организации
          |  (реорганизация);
        li Создавать список конкурсов, позволяющий обучающимся наполнять портфолио записями о победах в них;
        li Настраивать систему рейтингования образовательных организаций и образовательных программ в рамках системы ПФДО;
        li Модерировать мероприятия в сфере дополнительного образования других пользователей в навигаторе ПДО;
        li Размещать общедоступные документы и важную контактную информацию об ответственных лицах, связанных с внедрением
          |  ПФДО в регионе;
        li Автоматизировать процесс сбора, анализа и передачи данных по организациям, программам, мероприятиям дополнительного
          |  образования и основным статистическим показателям охвата детей дополнительным образованием в субъекте Российской
          |  Федерации Минобрнауки России в ЕАИС ДО;
        li Настроить интеграцию АИС «ПФДО» с мобильным приложением «ОНФ. Сертификат» (издатель: ОНФ: «Равные возможности —
          |  детям»). Приложение позволяет родителям мобильно управлять всеми возможностями сертификата их ребенка;
        li Пользоваться доступом к просмотру руководств по работе в АИС «ПФДО» всех пользователей (инструкции, видеоуроки,
          |  часто задаваемые вопросы);
        li Получать рассылку уведомлений о важных событиях, связанных с реализацией ПДО посредством использования АИС;
        li Размещать самому и модерировать новости в сфере дополнительного образования других пользователей в навигаторе ПДО;

</template>

<script>
export default {
  name: 'AboutOperator',
};
</script>

<style scoped>

</style>
