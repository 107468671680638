<template lang="pug">
  v-card.presentation__info(flat)
    v-card-text.pt-0
      h1.mb-4 ОБРАЗОВАТЕЛЬНОЙ ОРГАНИЗАЦИИ (РУКОВОДИТЕЛЮ, ЗАМЕСТИТЕЛЮ) базовая версия АИС «ПФДО» в режиме автоматизации позволяет:

      ul
        li Управлять информацией о помещениях организации (внесение адресов и кабинетов);
        li Управлять информацией о персонале организации, создавать для них кабинеты пользователей в АИС (педагоги, методисты);
        li Добавлять образовательные программы в навигатор и направлять в реестры программ;
        li Пользоваться доступом к просмотру результатов экспертиз образовательных программ;
        li Изменять информацию о реализуемых образовательных программах без необходимости удаления ранее внесённых данных;
        li Открывать, закрывать возможность зачисления детей на обучение по образовательным программам;
        li Пользоваться доступом к просмотру актуальных возможностей сертификатов детей при
          |  формировании заявок на обучение по образовательным программам организации;
        li Формировать заявки на обучение по образовательным программам с использованием сертификатов
          |  дополнительного образования (по бюджетным, сертифицированным и платным программам);
        li Формировать и заключать договоры об обучении по образовательным программам;
        li Пользоваться доступом к персональным данным детей/ родителей, обучающихся в группах педагога (при согласии родителей);
        li Заключать дополнительные соглашения по договорам об обучении по образовательным программам;
        li Расторгать договоры об обучении по образовательным программам (в том числе по согласию сторон с возвратом денег на
          |  сертификат);
        li Указывать ранее зачисленных детей на бюджетные образовательные программы, обучающие ся на втором и последующем годах
          |  обучения, с целью выполнения ранее взятых на себя обязательств по обучению детей. Это актуально на этапе внедрения
          |  ПДО;
        li Осуществлять перевод обучающихся на следующий учебный год (система позволяет массово формировать
          |  заявки на обучение на следующий учебный год вместо зачисления детей по одному);
        li Осуществлять пролонгацию договоров на обучение по программам, реализуемым в рамках действия двух финансовых периодов
          |  в муниципалитете (система позволяет массово сформировать пролонгированные договоры для продолжения обучения по
          |  программе в рамках следующего финансового периода вместо зачисления детей по одному);
        li Вносить и редактировать информацию о расписании занятий по образовательным программам;
        li Пользоваться доступом к информации о всех зачислениях и договорах на обучение по образовательным программам
          |  организации;
        li Формировать запрос на удаление договора об обучении в случае возникновения случая
          |  нарушения порядка зачисления детей со стороны сотрудников организаций;
        li Формировать запрос на реорганизацию образовательной организации (позволяет сохранять важную информацию о заключенных
          |  договорах на обучение, образовательных программах и сотрудниках в ситуации их ликвидации путём их присоединения к
          |  другой организации);
        li Вносить информацию о достижениях детей, обучающихся на образовательных программах;
        li Пользоваться доступом к документам и важной контактной информацией ответственных лиц, связанных с внедрением ПФДО в
          |  регионе;
        li Пользоваться доступом к просмотру руководств по работе в АИС «ПФДО» (инструкции, видеоуроки, часто задаваемые вопросы);
        li Получать рассылку уведомлений о важных событиях, связанных с реализацией ПДО посредством использования АИС (Например:
          |  Появление заявки на обучение от родителя; Изменение объёмов финансирования сертификатов в муниципалитетах детей,
          |  которые обучаются в организации; Включение или отказ от включения в реестры программ);
        li Размещать самой и пользоваться доступом к просмотру новостей в сфере дополнительного образования,
          |  размещаемым в региональном навигаторе дополнительного образования детей;
        li Формировать простые и аналитические отчёты (Программы; Счета; Обучающиеся (ПФ и МЗ); Список обучающихся в определенной
          |  группе; Возрастной состав учащихся на конец прошлого года; Возрастной состав учащихся прошлого календарного года по
          |  форме 1 -ДОП);
        li Заключать договоры об оплате дополнительного образования в рамках системы персонифицированного
          |  дополнительного образования;
        li Формировать приложения к счетам поставщиков образовательных услуг;
        li Вносить информацию о мероприятиях в сфере дополнительного образования в навигатор ПДО;
        li Позволяет создавать виджет и размещать его на своём сайте (ссылка с виджета ведёт на страницу образовательных
          |  программ в навигаторе);


</template>

<script>
export default {
  name: 'AboutOrganization',
};
</script>
