<template lang="pug">
  v-card.presentation__info(flat)
    v-card-text.pt-0
      h1.mb-4 УПОЛНОМОЧЕННОМУ ОРГАНУ / МУНИЦИПАЛИТЕТУ базовая версия АИС «ПФДО» в режиме автоматизации позволяет:

      ul
        li Контролировать соблюдение требований положения о персонифицированном дополнительном образовании и программы
          |  персонифицированного финансирования при использовании сертификатов дополнительного образования детьми муниципалитета
          |  (настройка возможностей сертификатов, регулирование процесса зачисления на сертифицированные и бюджетные программы);
        li Формировать реестр муниципальных образовательных организаций;
        li Распределять образовательные программы по бюджетным реестрам;
        li Формировать решение комиссии по реестрам (система формирует шаблон протокола комиссии с приложением, в котором
          |  зафиксировано принятое решение, в том числе с установленным целевым числом учащихся);
        li Сопровождать и/или вести реестр сертификатов дополнительного образования муниципалитета;
        li Управлять нормативной стоимостью образовательных программ;
        li Пользоваться доступом к информации о всех транзакциях по договорам об обучении;
        li Пользоваться доступом к информации о всех приложениях к счетам поставщиков образовательных услуг;
        li Пользоваться доступом к информации о реализации бюджетных и сертифицированных образовательных программ для детей
          |  муниципалитета;
        li Формировать статистическую информацию по использованию сертификатов дополнительного образования и оценивать охват
          |  детей дополнительным образованием в муниципалитете;
        li Формировать простые и аналитические отчёты. Например: Ход зачисления в организациях, учрежденных муниципалитетом;
          |  Количество обучающихся в организациях, учрежденных муниципалитетом; Списки обучающихся по учреждениям; Количество
          |  зачислений за указанный период в организациях, учрежденных муниципалитетом, по направленностям по конкретным
          |  организациям; Количество зачислений за указанный период в организациях, учрежденных муниципалитетом, по
          |  направленностям по типу организации; Справка о наполнении навигатора образовательными программами; Экспорт и импорт
          |  образовательных услуг;
        li Размещать общедоступные документы и важную контактную информацию об ответственных лицах, связанных с внедрением
          |  ПФДО в муниципалитете;
        li Пользоваться доступом к просмотру руководств по работе в АИС «ПФДО» (инструкции, видеоуроки, часто задаваемые
          |  вопросы);
        li Получать рассылку уведомлений о важных событиях, связанных с реализацией ПДО посредством использования АИС;
        li Размещать самому и пользоваться доступом к просмотру новостей в сфере дополнительного образования, размещаемым в
          |  региональном навигаторе дополнительного образования детей.

</template>

<script>
export default {
  name: 'AboutMunicipality',
};
</script>
