<template lang="pug">
  v-card.presentation__info(flat)
    v-card-text.pt-0
      h1.mb-4 НАБЛЮДАТЕЛЮ базовая версия АИС «ПФДО» в режиме автоматизации позволяет:
      p Видеть всю статистическую сводную информацию реализации проекта персонифицированного дополнительного образования
        |  федерального проекта «Успех каждого ребенка» в регионе, словно на «приборной панели».
      image-dialog
        v-img(:src="require('@/assets/images/observer.png')")

</template>

<script>
export default {
  name: 'AboutObserver',
};
</script>
