<template lang="pug">
  v-card.presentation__info(flat)
    v-card-text.pt-0
      h1.mb-4 МЕТОДИСТАМ ОБРАЗОВАТЕЛЬНЫХ ОРГАНИЗАЦИЙ базовая версия АИС «ПФДО» в режиме автоматизации позволяет:

      ul
        li Модерирование добавленных педагогами образовательных программ;
        li Пользоваться доступом к просмотру результатов экспертиз образовательных программ;
        li Пользоваться доступом к просмотру информации о всех образовательных программах организации;
        li Создавать и удалять кабинеты пользователя в АИС для педагогов организации;
        li Вносить информацию о расписании занятий по образовательным программам и назначать педагогов, реализующих эти занятия;
        li Формировать заявки на обучение по образовательным программам с использованием сертификатов
          |  дополнительного образования (по бюджетным, сертифицированным и платным программам);
        li Указывать ранее зачисленных детей на бюджетные образовательные программы, обучающиеся на втором и последующем годах
          |  обучения, с целью выполнения ранее взятых на себя обязательств по обучению детей. Это актуально на этапе внедрения
          |  ПДО;
        li Пользоваться доступом к просмотру актуальных возможностей сертификатов детей при формировании
          |  заявок на обучение по образовательным программам организации;
        li Пользоваться доступом к информации о всех зачислениях и договорах на обучение по
          |  образовательным программам организации;
        li Пользоваться доступом к документам и важной контактной информацией ответственных лиц, связанных с внедрением ПФДО в
          |  регионе;
        li Пользоваться доступом к просмотру руководств по работе в АИС «ПФДО» (инструкции, видеоуроки, часто задаваемые
          |  вопросы);
        li Получать рассылку уведомлений о важных событиях, связанных с реализацией ПДО посредством использования АИС;
        li Пользоваться доступом к просмотру новостей в сфере дополнительного образования, размещаемым в
          |  региональном навигаторе дополнительного образования детей;

</template>

<script>
export default {
  name: 'AboutMethodist',
};
</script>
