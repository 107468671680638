<template lang="pug">
  v-card.presentation__info(flat)
    v-card-text.pt-0
      h1.mb-4 УПОЛНОМОЧЕННОЙ ОРГАНИЗАЦИИ/ ФИНАНСОВОМУ ОПЕРАТОРУ (ОПЕРАТОРСКАЯ МОДЕЛЬ) базовая версия АИС «ПФДО»
        |  в режиме автоматизации позволяет:

      ul
        li Заключать договоры об оплате дополнительного образования в рамках системы персонифицированного дополнительного
          |  образования;
        li Получать уведомления о достижении максимальной цены по договору для последующего дополнительного соглашения с
          |  поставщиком образовательных услуг (если финансовый оператор - автономное учреждение);
        li Пользоваться доступом к информации о всех транзакциях по договорам об обучении;
        li Пользоваться доступом к информации о всех приложениях к счетам поставщиков образовательных услуг;
        li Работать со счетами, выставляемыми поставщикам образовательных услуг (изменение статуса счёта в процессе его
          |  обработки, инициирование перерасчёта оплаты по договорам на обучение);
        li Формировать простые и аналитические отчёты (программы, соглашения, договоры, счета);
        li Формировать заявки на получение субсидии от уполномоченного органа муниципалитета;
        li Пользоваться доступом к документам и важной контактной информацией ответственных лиц, связанных с внедрением ПФДО в
          |  регионе;
        li Пользоваться доступом к просмотру руководств по работе в АИС «ПФДО» (инструкции, видеоуроки, часто задаваемые
          |  вопросы);
        li Получать рассылку уведомлений о важных событиях, связанных с реализацией ПДО посредством использования АИС;
        li Пользоваться доступом к просмотру новостей в сфере дополнительного образования, размещаемым в
          |  региональном навигаторе дополнительного образования детей;
        li Формировать простые и аналитические отчёты (программы, соглашения, договоры, счета);

</template>

<script>
export default {
  name: 'AboutPayer',
};
</script>
