<template lang="pug">
  v-container.full-height(v-if="temporaryCode !== 'PfdO'" grid-list-md)
    v-layout.mb-3.full-height(row align-center justify-center)
      v-flex(md4 sm8 xs12)
        v-text-field(
          v-model="temporaryCode"
          outlined
          label="Ласкаво просимо"
        )
  v-container(v-else grid-list-md)
    v-layout.mb-3(row align-center)
      v-flex
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            h1.text-uppercase(v-on="on") АИС «ПФДО»
          span Автоматизированная информационная система персонифицированного дополнительного образования детей
      v-flex.text-right
        v-btn(color="primary" depressed :href="pdf" target="_blank") Скачать презентацию

    v-layout
      v-flex
        v-navigation-drawer.presentation__navigator(floating permanent stateless value="true")
          v-list.lesslight(dense)
            v-list-item.mb-2(
              v-for="(item, idx) in navItems"
              :key="idx"
              @click="infoComponent = item.component"
              :class="{ 'v-list-item--active': infoComponent === item.component }"
            )
              v-list-item-content
                v-list-item-title {{ item.text }}
      v-flex
        keep-alive
          component(:is="infoComponent")

</template>

<script>
import AboutAisPfdo from './components/AboutAisPfdo';
import AboutCertificate from './components/AboutCertificate';
import AboutExpert from './components/AboutExpert';
import AboutMethodist from './components/AboutMethodist';
import AboutMunicipality from './components/AboutMunicipality';
import AboutObserver from './components/AboutObserver';
import AboutOperator from './components/AboutOperator';
import AboutOrganization from './components/AboutOrganization';
import AboutPayer from './components/AboutPayer';
import AboutPedagogue from './components/AboutPedagogue';
import AboutUser from './components/AboutUser';
import AboutAisPfdo2 from './components/AboutAisPfdo2';

const pdf = require('@/assets/presentation.pdf');

export default {
  name: 'ThePresentation',
  components: {
    AboutAisPfdo2,
    AboutUser,
    AboutPedagogue,
    AboutPayer,
    AboutOrganization,
    AboutOperator,
    AboutObserver,
    AboutMunicipality,
    AboutMethodist,
    AboutExpert,
    AboutCertificate,
    AboutAisPfdo,
  },
  data: () => ({
    infoComponent: 'about-ais-pfdo',
    navItems: [
      { component: 'about-ais-pfdo', text: 'АИС «ПФДО»' },
      { component: 'about-operator', text: 'Региональный оператор' },
      { component: 'about-observer', text: 'Наблюдатель (от министерства образования)' },
      { component: 'about-expert', text: 'Эксперт / Независимый эксперт / МОЦ' },
      { component: 'about-municipality', text: 'Уполномоченный орган / Муниципалитету' },
      { component: 'about-payer', text: 'Уполномоченная организация / Фин. оператор (Операторская модель)' },
      { component: 'about-organization', text: 'Образовательная организация (РУКОВОДИТЕЛЬ, ЗАМЕСТИТЕЛЬ и т.д.)' },
      { component: 'about-pedagogue', text: 'Педагог образовательной организации' },
      { component: 'about-methodist', text: 'Методист образовательной организации' },
      { component: 'about-certificate', text: 'Сертификат / ребенок / водитель' },
      { component: 'about-user', text: 'Неавторизованный пользователь' },
      { component: 'about-ais-pfdo2', text: 'АИС «ПФДО»' },
    ],
    pdf,
    temporaryCode: null,
  }),
};
</script>

<style scoped lang="scss">

::v-deep h1 {
    line-height: 1.2;
  }

  .presentation__navigator {
    position: sticky;
    top: 90px!important;
    height: max-content!important;

    .lesslight {
      background-color: $lesslight-color;
    }
  }

  ::v-deep .v-list-item {
    height: auto!important;
  }

  ::v-deep .v-list-item__title {
    overflow: visible;
    white-space: normal;
    height: auto;
    line-height: 1.3;
  }

  ::v-deep .presentation__info {

    .v-responsive__content {
      width: 100% !important;
    }

    p {
      font-size: 16px;
    }

    li {
      margin-top: 8px;
      font-size: 16px;
    }

  }

</style>
