<template lang="pug">
  v-card.presentation__info(flat)
    v-card-text.pt-0
      h1.mb-4 ЭКСПЕРТУ/ НЕЗАВИСИМОМУ ЭКСПЕРТУ/ МОЦу базовая версия АИС «ПФДО» в режиме автоматизации позволяет:

      ul
        li Участвовать в сертификации образовательных программ;
        li Модерировать информацию о достижениях детей по итогу их участия в конкурсах;
        li Проводить экспертизу и оценку качества образовательных программ;
        li Пользоваться доступом к документам и важной контактной информацией ответственных лиц, связанных с внедрением ПФДО в
          |  регионе;
        li Пользоваться доступом к просмотру руководств по работе в АИС «ПФДО» (инструкции, видеоуроки, часто задаваемые вопросы);
        li Получать рассылку уведомлений о важных событиях, связанных с реализацией ПДО посредством использования АИС;
        li Пользоваться доступом к просмотру новостей в сфере дополнительного образования, размещаемым в региональном навигаторе
          |  дополнительного образования детей.

</template>

<script>
export default {
  name: 'AboutExpert',
};
</script>
